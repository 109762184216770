<template>
  <div>
    <b-modal
      scrollable
      title-class="h3 text-white font-weight-bolder"
      title="Detail Recommended Services"
      size="xls"
      ref="myModalRecommend"
      @hidden="closeModal"
      centered
    >
      <template #modal-footer>
        <b-button variant="primary" @click="returnAction(dataClient.id,'APPROVED')">
          APPROVED
        </b-button>
        <b-button variant="warning" @click="returnAction(dataClient.id,'REJECTED')">
          REJECTED
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ServicesRecomendation from "@/views/specialists/sub-modules/financial-approval/views/recomendations/services/recomendations.service.js";

export default {
  components: {},
  props: {
    dataClient: {
      required: true,
      type: Object,
    },
    revision: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      detailWorkplan: {
        strategies: [],
        services: [],
        comments: "",
      },
      finishedLoading: false,
      showRoundDetail: false,
      dataRound: {
        personal_information: [],
        inquiries: [],
        public_records: [],
        accounts: [],
      },
      modalTitle: "",
      creditUtilized: [],
      rounds: [],
      directs: {
        type: "1",
        title: "Directs",
        data: [],
      },
      letters: {
        type: "2",
        title: "Letters",
        data: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("myModalRecommend");
  },
  async created() {
    this.finishedLoading = true;
  },

  methods: {
    ...mapActions({
      A_UPDATE_REQUEST_COUNTERS:
        "BoostCreditDisputeResult/A_UPDATE_REQUEST_COUNTERS",
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
    }),

    async returnAction(id,status) {
    try {
        let res;
        const swal = await this.showConfirmSwal("Are you sure?");
        if (swal.isConfirmed) {
          this.addPreloader();
          const params = {
            id,
            status,
            updatedBy: this.currentUser.user_id,
          };
          res = await ServicesRecomendation.updateStatus(params);
          this.showSuccessSwal();
          this.$emit("reload");
          this.closeModal();
          return res;
        }
      
    } catch (error) {
      this.showErrorSwal(error);
    } finally {
      this.removePreloader();
    }
    },

    closeModal() {
      this.$emit("close");
    },
  },

};
</script>

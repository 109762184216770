import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

const filters = [
    {
        key: "from",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'from'
    },
    {
        key: "to",
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
        nameFilter: 'to'
    },
    {
        key: "specialist",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Specialist",
        model: null,
        options: [],
        reduce: "id",
        selectText: "text",
        cols: 12,
        visible: true,
    },
    {
        key: "client_type",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Client type",
        model: null,
        options: [
            { id: 1, text: "New Client" },
            { id: 2, text: "Regular" },
            { id: 3, text: "Returned" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 6,
        visible: true,
    },
    {
        key: "risky_level",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Risky level",
        model: null,
        options: [
            { id: 1, text: "No Risk" },
            { id: 2, text: "Low Risk" },
            { id: 3, text: "Medium Risk" },
            { id: 4, text: "High Risk" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 6,
        visible: true,
        disabled: true,
    },
    {
        key: "department",
        type: "select",
        margin: true,
        showLabel: true,
        label: "Department",
        model: null,
        options: [
            // { id: 14, text: "Bookeeping" },
            { id: 7, text: "Boost Credit" },
            // { id: 3, text: "Business" },
            // { id: 10, text: "Court Info" },
            { id: 20, text: "Connection" },
            { id: 6, text: "Credit Experts" },
            { id: 22, text: "Customer Service Digital" },
            { id: 5, text: "Debt Solution" },
            // { id: 12, text: "Paragon" },
            // { id: 11, text: "Specialist" },
            // { id: 8, text: "Tax Research" },
        ],
        reduce: "id",
        selectText: "text",
        cols: 12,
        visible: true,
    },
];

const processFilters = async () => {
    const { users } = await RequestWorkPlanService.getCreditAnalysts();
    users.sort(function (a, b) {
        let textA = a.text.toUpperCase();
        let textB = b.text.toUpperCase();

        if (textA < textB) {
            return -1;
        }
        if (textA > textB) {
            return 1;
        }
        return 0;
    });
    filters[2].options = users;
};

processFilters();

export default filters;
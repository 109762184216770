var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-0 border-0 p-0",attrs:{"no-body":""}},[_c('filter-slot',{attrs:{"filter":[],"total-rows":_vm.FiltersForSlot.totalRows,"paginate":_vm.FiltersForSlot.paginate,"start-page":_vm.FiltersForSlot.startPage,"to-page":_vm.FiltersForSlot.toPage,"filter-principal":_vm.FiltersForSlot.filterPrincipal},on:{"reload":function($event){return _vm.$refs['RecomendationsTable'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"RecomendationsTable",staticClass:"blue-scrollbar",attrs:{"slot":"table","items":_vm.myProvider,"fields":_vm.visibleFields,"busy":_vm.isBusy,"responsive":"md","sticky-header":"50vh","show-empty":"","no-provider-filtering":"","per-page":_vm.FiltersForSlot.paginate.perPage,"current-page":_vm.FiltersForSlot.paginate.currentPage},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('IconStatusAccount',{attrs:{"status":item.status_account_id,"fromBc":""}}),_c('router-link',{attrs:{"target":"_blank","to":{
                name: _vm.clientDashboardRouteName,
                params: {
                  idClient: ("" + (item.client_account_id)),
                },
              }}},[_vm._v(" "+_vm._s(item.client_name)+" ")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-block",staticStyle:{"margin-left":"18px"}},[_vm._v(" "+_vm._s(item.client_account)+" ")])])]}},{key:"cell(recomendation_by)",fn:function(data){return [_c('span',[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}),_vm._v(" "+_vm._s(data.item.recomendation_by)+" ")],1)]}},{key:"cell(rejected_by)",fn:function(data){return [_c('span',[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}),_vm._v(" "+_vm._s(data.item.rejected_by)+" ")],1),_c('br'),_c('span',[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"20"}}),_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.updated_at))+" ")],1)]}},{key:"cell(completed_by)",fn:function(data){return [_c('span',[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}),_vm._v(" "+_vm._s(data.item.completed_by)+" ")],1),_c('br'),_c('span',[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"20"}}),_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.updated_at))+" ")],1)]}},{key:"cell(specialist)",fn:function(data){return [_c('span',[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}}),_vm._v(" "+_vm._s(data.item.specialist)+" ")],1)]}},{key:"cell(recommended_services)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center col-12",staticStyle:{"flex-wrap":"wrap","gap":"5px"}},[_vm._l((_vm.displayedServices(item)),function(data,index){return [_c('b-badge',{key:index,attrs:{"variant":"light-info"}},[_c('feather-icon',{attrs:{"icon":"GridIcon"}}),_vm._v(" "+_vm._s(data.charge || data.name)+" ")],1)]}),(_vm.shouldShowToggle(item))?_c('span',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){return _vm.toggleShowAll(item)}}},[_vm._v(" "+_vm._s(item.showAll ? "see less..." : "see more...")+" ")]):_vm._e(),(!_vm.displayedServices(item))?_c('b-badge',{attrs:{"variant":"light-danger"}},[_c('feather-icon',{attrs:{"icon":"SlashIcon"}}),_vm._v(" None ")],1):_vm._e()],2)]}},{key:"cell(actions)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Completed Update Request'),expression:"'Completed Update Request'",modifiers:{"hover":true,"right":true}}],staticClass:"text-primary cursor-pointer mr-1",attrs:{"size":"20","icon":"EyeIcon"},on:{"click":function($event){return _vm.openActionModal(item)}}})],1)]}}])})]},proxy:true}])}),(_vm.showModalAction)?_c('DetailRecomendations',{attrs:{"dataClient":_vm.dataWp},on:{"close":function($event){_vm.showModalAction = false},"reload":_vm.refresh}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
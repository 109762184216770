import { amgApi } from "@/service/axios";

class RecomendationsClientService{

  async search(params) {
    try {
      const { data } = await amgApi.post("/specialists/get-recomendations-services-clients", params)
      return data
    } catch (error) {
      console.log("recomendations-services-clients", error);
      throw error
    }
  }

  async updateStatus(params) {
    try {
      const { data } = await amgApi.post("/specialists/change-recommended-services-status-client", params)
      return data
    } catch (error) {
      console.log("Something went wrong on updateStatus", error);
      throw error
    }
  }

}

export default new RecomendationsClientService();

export default [
    {
      key: "client",
      label: "Client",
      class: "text-left",
      visible: true,
    },
    {
      key: "departament",
      label: "module",
      class: "text-center",
      visible: false,
    },
    {
      key: "recommended_services",
      label: "recommendations",
      class: "text-center",
      visible: true,
    },
    {
      key: "recomendation_by",
      label: "Recommended by",
      class: "text-center",
      visible: true,
    },
    {
      key: "completed_by",
      label: "Approved by",
      class: "text-center",
      visible: true,
    },
    {
      key: "specialist",
      label: "Specialist",
      class: "text-center",
      visible: true,
    },
     {
      key: "rejected_by",
      label: "Rejected by",
      class: "text-center",
      thClass: "px-1",
      visible: true,
    }, 
    {
      key: "actions",
      label: "Actions",
      class: "text-center",
      visible: true,
    },
  ];
  
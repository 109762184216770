<template>
  <b-card no-body class="mb-0 border-0 p-0">
    <filter-slot
      :filter="[]"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['RecomendationsTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="RecomendationsTable"
          slot="table"
          class="blue-scrollbar"
          :items="myProvider"
          :fields="visibleFields"
          :busy.sync="isBusy"
          responsive="md"
          sticky-header="50vh"
          show-empty
          no-provider-filtering
          :per-page="FiltersForSlot.paginate.perPage"
          :current-page="FiltersForSlot.paginate.currentPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client)="{ item }">
            <span class="d-flex align-items-center" style="gap: 5px">
              <IconStatusAccount :status="item.status_account_id" fromBc />
              <!-- {{ item.client_name }} -->
              <router-link
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${item.client_account_id}`,
                  },
                }"
              >
                {{ item.client_name }}
              </router-link>
            </span>
            <div class="d-flex align-items-center">
              <span class="d-block" style="margin-left: 18px">
                {{ item.client_account }}
              </span>
            </div>
          </template>
          <template #cell(recomendation_by)="data">
            <span>
              <feather-icon icon="UserIcon" size="20" />
              {{ data.item.recomendation_by }}
            </span>
          </template>

          <template #cell(rejected_by)="data">
            <span>
              <feather-icon icon="UserIcon" size="20" />
              {{ data.item.rejected_by }}
            </span>
            <br />
            <span>
              <feather-icon icon="CalendarIcon" size="20" />
              {{ data.item.updated_at | myGlobal }}
            </span>
          </template>

          <template #cell(completed_by)="data">
            <span>
              <feather-icon icon="UserIcon" size="20" />
              {{ data.item.completed_by }}
            </span>
            <br />
            <span>
              <feather-icon icon="CalendarIcon" size="20" />
              {{ data.item.updated_at | myGlobal }}
            </span>
          </template>

          <template #cell(specialist)="data">
            <span>
              <feather-icon icon="UserIcon" size="20" />
              {{ data.item.specialist }}
            </span>
          </template>

          <template #cell(recommended_services)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center col-12"
              style="flex-wrap: wrap; gap: 5px"
            >
              <template v-for="(data, index) in displayedServices(item)">
                <b-badge variant="light-info" :key="index">
                  <feather-icon icon="GridIcon" />
                  {{ data.charge || data.name }}
                </b-badge>
              </template>
              <span
                @click="toggleShowAll(item)"
                v-if="shouldShowToggle(item)"
                class="text-primary cursor-pointer"
              >
                {{ item.showAll ? "see less..." : "see more..." }}
              </span>
              <b-badge v-if="!displayedServices(item)" variant="light-danger">
                <feather-icon icon="SlashIcon" /> None
              </b-badge>
            </div>
          </template>

          <template #cell(actions)="{ item }">
            <div class="d-flex justify-content-center align-items-center">
              <feather-icon
                class="text-primary cursor-pointer mr-1"
                v-b-tooltip.hover.right="'Completed Update Request'"
                @click="openActionModal(item)"
                size="20"
                icon="EyeIcon"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <DetailRecomendations
      v-if="showModalAction"
      :dataClient="dataWp"
      @close="showModalAction = false"
      @reload="refresh"
    />
  </b-card>
</template>


<script>
//SERVICES
import RecomendationsServiceClient from "@/views/specialists/sub-modules/financial-approval/views/recomendations/services/recomendations.service.js";

import Filters from "@/views/commons/components/request-workplan/data/filters.js";
import fields from "@/views/specialists/sub-modules/financial-approval/views/recomendations/data/field.js";

// COMPONENTS
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";

// STORE
import { mapGetters } from "vuex";

import DetailRecomendations from "@/views/specialists/sub-modules/financial-approval/views/recomendations/views/modal/DetailRecomendations.vue";

export default {
  components: {
    IconStatusAccount,
    DetailRecomendations,
  },

  data() {
    return {
      fields: fields,
      items: [],
      typeReport: null,
      FiltersForSlot: {
        filters: Filters,
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      showModalAction: false,
    };
  },

  computed: {
    ...mapGetters({
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    isPendingTab() {
      const { routeRecomendationPending } = this.$route.matched[1].meta;
      return routeRecomendationPending == this.$route.name;
    },
    isApprovedTab() {
      const { routeRecomendationApproved } = this.$route.matched[1].meta;
      return routeRecomendationApproved == this.$route.name;
    },
    isRejectedTab() {
      const { routeRecomendationRejected } = this.$route.matched[1].meta;
      return routeRecomendationRejected == this.$route.name;
    },
    currentTab() {
      let obj = {
        1: "PENDING",
        2: "APPROVED",
        3: "REJECTED",
      };
      return obj[this.$route.matched[2].meta.status];
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
  },

  created() {
    this.filterColumn("completed_by", this.isApprovedTab);
    this.filterColumn("rejected_by", this.isRejectedTab);
    this.filterColumn("actions", this.isPendingTab);
  },

  methods: {
    reloadTable() {
      this.$refs["RecomendatiosnTable"].refresh();
    },

    getFilterByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `${key}`
      );

      return filter;
    },

    async myProvider(ctx) {
      try {
        const params = {
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          per_page: ctx.perPage,
          page: ctx.currentPage,
          status: this.currentTab,
          text: this.FiltersForSlot.filterPrincipal.model,
        };
        const result = await RecomendationsServiceClient.search(params);
        const data = result.data.map((item) => ({ ...item, showAll: false }));

        this.FiltersForSlot.startPage = result.from;
        this.FiltersForSlot.paginate.currentPage = result.current_page;
        this.FiltersForSlot.paginate.perPage = result.per_page;
        this.FiltersForSlot.totalRows = result.total;
        this.FiltersForSlot.toPage = result.to;

        return data || [];
      } catch (error) {
        return [];
      }
    },

    toggleShowAll(item) {
      item.showAll = !item.showAll;
    },

    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    shouldShowToggle(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return services.length > 2;
      }
    },

    displayedServices(item) {
      if (item.recommended_services) {
        const services = JSON.parse(item.recommended_services);
        return item.showAll ? services : services.slice(0, 2);
      }
    },

    async openActionModal(item) {
      this.showModalAction = true;
      this.dataWp = item;
    },

    refresh() {
      this.$refs.RecomendationsTable.refresh();
    },
  },
};
</script>